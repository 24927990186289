import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import LeafletMap from "./components/map";
import L from "leaflet";
import { RotateCw } from "lucide-react";

const CACHE_KEY = "mapDataCache";
const CACHE_DURATION = 3600000; // 1 hour in milliseconds

function App() {
  const [adplays, setAdplays] = useState([]);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState([9.0244164, 7.3674652]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [timeUntilRefresh, setTimeUntilRefresh] = useState(0);

  function convertToLatLongArray(dataArray) {
    return dataArray.map((item) => {
      const [lat, lng, timestamp, isMoving] = item.split("/");
      return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        timestamp: parseInt(timestamp, 10),
        isMoving,
      };
    });
  }

  const fetchMapPoints = useCallback(async () => {
    try {
      const token =
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ];
      const response = await fetch("/myadstat/" + token);
      const data = await response.json();
      const convertedData = convertToLatLongArray(data.stats?.playLocations || []);

      // Update cache
      const cacheData = {
        data: convertedData,
        timestamp: Date.now(),
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));

      setAdplays(convertedData);
      setLastUpdate(Date.now());
      updateMapView(convertedData);
    } catch (error) {
      console.error("Error fetching map points:", error);
      const dummyData = [
        {
          lat: 7.79791,
          lng: 4.56576,
          timestamp: 1632916800000,
          isMoving: true,
        },
        {
          lat: 7.78898,
          lng: 4.56967,
          timestamp: 1632922200000,
          isMoving: true,
        },
        { lat: 7.7968, lng: 4.54499, timestamp: 1632916800000, isMoving: true },
        {
          lat: 7.79221,
          lng: 4.53731,
          timestamp: 1632922200000,
          isMoving: true,
        },
      ];
      setAdplays(dummyData);
      updateMapView(dummyData);
    }
  }, []);

  useEffect(() => {
    const loadData = () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        const now = Date.now();
        if (now - timestamp < CACHE_DURATION) {
          setAdplays(data);
          setLastUpdate(timestamp);
          updateMapView(data);
          return;
        }
      }
      fetchMapPoints();
    };

    loadData();
  }, [fetchMapPoints]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (lastUpdate) {
        const timeSinceUpdate = Date.now() - lastUpdate;
        const timeLeft = CACHE_DURATION - timeSinceUpdate;
        setTimeUntilRefresh(Math.max(0, timeLeft));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastUpdate]);

  const updateMapView = (data) => {
    if (data.length > 0) {
      const bounds = L.latLngBounds(
        data.map((point) => [point.lat, point.lng])
      );
      setCenter(bounds.getCenter());

      // Calculate appropriate zoom level
      const latitudeDiff = Math.abs(bounds.getNorth() - bounds.getSouth());
      const longitudeDiff = Math.abs(bounds.getEast() - bounds.getWest());

      // This is a simple heuristic - adjust these values based on your needs
      if (latitudeDiff < 1 && longitudeDiff < 1) {
        setZoom(13); // City level
      } else if (latitudeDiff < 5 && longitudeDiff < 5) {
        setZoom(10); // Region level
      } else if (latitudeDiff < 20 && longitudeDiff < 20) {
        setZoom(8); // Country level
      } else {
        setZoom(6); // Continent level
      }
    }
  };

  const formatTimeLeft = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="App w-full h-full relative">
      <LeafletMap markers={adplays} center={center} zoom={zoom} />
      <div className="absolute top-4 right-4 z-[1000]">
        <button
          onClick={fetchMapPoints}
          className="bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
          title={
            timeUntilRefresh > 0
              ? `Refresh in ${formatTimeLeft(timeUntilRefresh)}`
              : "Refresh now"
          }
        >
          <RotateCw
            className={`w-6 h-6 ${
              timeUntilRefresh === 0 ? "text-nibi" : "text-gray-400"
            }`}
          />
        </button>
      </div>
    </div>
  );
}

export default App;
