import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const COLORS = {
  DEFAULT: "#ffb400",
  secondary: "#28b446",
  primarybg: "#ffc436",
};

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, zoom, { duration: 1 });
  }, [center, zoom, map]);
  return null;
};

// Function to format the timestamp
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return {
    time: date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    date: date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }),
  };
};

const LeafletMap = ({ markers, center, zoom }) => {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <MapContainer
        center={center}
        zoom={zoom}
        style={{ height: "100%", width: "100%" }}
        zoomControl={true}
        maxZoom={18}
        minZoom={2}
      >
        <ChangeView center={center} zoom={zoom} />

        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {markers.map((marker, index) => (
          <CircleMarker
            key={index}
            center={[marker.lat, marker.lng]}
            radius={8}
            fillColor={COLORS.DEFAULT}
            color={COLORS.DEFAULT}
            weight={1}
            opacity={1}
            fillOpacity={marker.isMoving? 0.8: 0.3}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            <Popup className="text-white p-3 rounded">
              <div>
                <strong>Time Played:</strong>
                <br />
                <span className="text-nibi">
                  {formatTimestamp(marker.timestamp).time} {" "}
                  {/* <br /> */}
                  {formatTimestamp(marker.timestamp).date}
                </span>
                <br />
                <strong>Play Location:</strong>
                <br />
                <span className="text-nibi">
                  Lat: {marker.lat}, Lng: {marker.lng}
                </span>
                <br />
                <span className="text-nibi">
                  status: {marker.isMoving? "moving":"stationary"}
                </span>
              </div>
            </Popup>
          </CircleMarker>
        ))}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
